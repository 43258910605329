import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/common/Layout"
import Gokart from '../components/tracks/Gokart'
import { MetaData } from '../components/common/meta'
import racingImage from '../images/racing-1600x900.jpg'


const Track = ({ track }) => (
  <li>
    <a href={`/${track.url}`}>{track.trackgroup}</a>
  </li>
)

const Tracks = ({ location, data }) => (
<>
<MetaData
        title="Popular Racing Tracks In The World"
        description="Find popular motorsport racing tracks around the world and near to you."
        location={location}
        image={racingImage}
    />
  <Layout showLocation>
    <Gokart trackCount={10} hideGoKart hideRacingLines/>
    <ul className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {data.allTracksGroupedJson.nodes.map(track => (
        <Track track={track} key={track.id} />
      ))}
    </ul>
  </Layout>
  </>
)

export default Tracks

export const query = graphql`
  {
    allTracksGroupedJson {
      nodes {
        id
        trackgroup
        url
      }
    }
  }
`
